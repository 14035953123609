*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  font-size: var(--bs-root-font-size);
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(67, 89, 113, 0);
}

/* 
-----------------------------------
GLOBAL STYLES
-----------------------------------
 */

.text-white {
  color: var(--bs-white) !important;
}

.text-black {
  color: var(--bs-black) !important;
}
.container {
  padding: 0 8%;
  margin: auto;
}

.text-secondary {
  color: var(--bs-primary-3) !important;
}
.text-primary {
  color: var(--bs-primary) !important;
}

.text-primary-2 {
  color: var(--bs-primary-2) !important;
}

.text-left {
  text-align: left !important;
}

.text-gray {
  color: #697a8d !important;
}

.percentage-text-large {
  font-size: 50px;
  font-weight: 600;
}
.percentage-text {
  font-size: 35px;
  font-weight: 600;
}
.bg-white {
  background-color: var(--bs-white);
}

.invisible {
  visibility: hidden;
}

/* 
-----------------------------------
TOPBAR STYLES
-----------------------------------
 */

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 0 16px;
  background-color: var(--bs-white);
  margin: 10px;
  color: var(--bs-white);
  text-align: center;
  border-radius: 10px;
  box-shadow: var(--bs-box-shadow);
}

.search-container {
  display: flex;
  align-items: center;
}

.search-icon {
  font-size: 18px;
  margin-right: 8px;
  color: #8c8c8c;
}

.search-input {
  width: 200px;
  outline: none;
  border: none;
}

.search-input:focus,
.search-input:active {
  outline: none !important;
  border: none !important;
}

.user-dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile-item {
  display: flex;
  align-items: center;
}

.profile-info {
  margin-left: 10px;
}

.profile-name {
  font-weight: bold;
}

.profile-role {
  font-size: 12px;
  color: #8c8c8c;
}

.menu-item-text {
  margin-left: 8px;
}

/* 
-----------------------------------
SIDEBAR STYLES
-----------------------------------
 */

.sidebar {
  background: var(--bs-white) !important;
  box-shadow: var(--bs-box-shadow);
}

.sidebar .app-brand {
  height: 64px;
  margin-bottom: 30px;

  text-align: center;
}

.sidebar.app-brand-link {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.sidebar .app-brand-link {
  font-size: 35px !important;
  font-weight: 600;
  margin-bottom: 0px !important;
  margin-top: 15px !important;
}

/* 
-----------------------------------
HEADER STYLES
-----------------------------------
 */

.navbar {
  width: 100%;
  transition: 0.3s ease;
  background: var(--bs-white);
}

.navbar.scrolled {
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: var(--bs-white);
  box-shadow: var(--bs-box-shadow);
}

.navContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* Desktop Menu */
.desktopMenu {
  display: flex;
}

.mobileMenu {
  display: none;
}

.navMenu {
  flex: 1;
  border: none;
  width: 530px;
}

.brandText {
  font-weight: 600 !important ;
  font-size: 30px !important;
  cursor: pointer;
  margin-top: 10px;
}

.ant-menu-horizontal {
  line-height: 30px !important;
}

.ant-menu-item {
  display: inline-block;
  white-space: nowrap;
}

/* 
-----------------------------------
FOOTER STYLES
-----------------------------------
 */

.footer {
  background-color: var(--bs-primary);
  padding: 20px 50px;
}

.footer-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--bs-white) !important;
}

.footer-link {
  color: white;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

/* 
-----------------------------------
HOME PAGE STYLES
-----------------------------------
 */

.intro-section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  margin-top: 20px;
  margin-bottom: 20px;
}

.custom-card {
  color: white;
  border-radius: 10px;
}

.icon-container {
  border-radius: 50% !important;
  width: 80px !important;
  height: 80px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; /* Ensure icon color is white */
}

.icon {
  font-size: 40px;
}

.bg-primary {
  background-color: var(--bs-primary);
}

.bg-primary-2 {
  background-color: var(--bs-primary-2);
}

.bg-primary-3 {
  background-color: var(--bs-primary-3);
}

.card-description {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro-section .card-description {
  min-height: 110px;
}

/* 
-----------------------------------
STUDENT TOUR PAGE STYLES
-----------------------------------
 */

.section {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.section-title {
  margin-bottom: 40px !important;
}
.image-container {
  width: 100%;
  border-radius: 10px;
}

.line-break {
  white-space: pre-line;
}

/* .carousel-card .card-description {
  display: -webkit-box;
  -webkit-line-clamp: 4; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
} */

#exclusions .ant-card {
  height: 220px;
}
.carousel-card .ant-card {
  height: 400px;
}

.hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url(https://res.cloudinary.com/dbgw9jgum/image/upload/v1729228352/adventure-hike-people-concept-group-smiling-friends-forest-man-with-map_txmhym.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 62vh;
  display: flex;
  justify-content: start;
  align-items: center;
}

.custom-card {
  background-color: transparent !important;
  box-shadow: none !important;
}

.card-column {
  border-right: 1px solid #ddd;
}

.card-column:last-child {
  border-right: none;
}

.card-container {
  border-bottom: 1px solid #ddd;
}

.card-container:last-child {
  border-bottom: none; /* Remove bottom border from last card */
}

.carousel-container {
  position: relative;
}

.carousel-card {
  padding: 10px;
  width: 500px;
}

.carousel-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--bs-primary);
  color: var(--bs-white);
  border: none;
  z-index: 1;
}

.left-arrow {
  left: -40px;
}

.right-arrow {
  right: -40px;
}

/* 
-----------------------------------
DASHBOARD STYLES
-----------------------------------
 */

.choose-dashboard-type {
  padding: 2rem;
  margin-top: 0;
}

/* .choose-dashboard-type .card-title {
  min-height: 30px;
} */

.choose-dashboard-type .ant-card {
  min-height: 200px;
}

.choose-dashboard-type .card-description {
  min-height: 100px;
}

.dashboard-layout {
  min-height: 100vh;
}

.dashboard-layout .ant-layout-sider {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  z-index: 10;
}

.main-layout {
  margin-left: 200px; /* Adjust based on sidebar width */
  min-height: 100vh;
}

.main-layout .ant-layout-content {
  padding: 10px !important;
}

/* .main-layout .ant-layout-header {
  position: fixed;
  top: 0;
  left: 200px;
  width: calc(100% - 200px);
  z-index: 10;
} */

.dashboard-content {
  /* margin-top: 64px;  */
  padding: 16px; /* Optional: add some padding */
  overflow: auto;
  height: calc(100vh - 64px); /* Adjust based on header height */
  background: #f0f2f5; /* Optional: Background color */
}

.welcome-card {
  border-radius: 8px; /* Optional: Rounded corners for the card */
  overflow: hidden; /* Ensures the content fits neatly */
  height: 185px;
}

.welcome-card .description {
  margin-top: 8px;
  display: block; /* Ensures spacing below */
  font-size: 14px;
}

.image-container {
  text-align: center; /* Center-aligns the image on small screens */
}

.welcome-image {
  max-width: 50%;
  height: auto; /* Ensures responsiveness */
}
.output-container {
  margin-top: 1rem;
}
/* 
-----------------------------------
GLOBAL ANTD OVERRIDE STYLES
-----------------------------------
 */

.ant-carousel .slick-slide {
  padding: 0 10px;
}

.ant-form-item .ant-form-item-label > label {
  color: var(--bs-black) !important;
}

.ant-btn {
  margin-top: 20px;
}

h3.ant-typography,
h2.ant-typography,
h4.ant-typography {
  font-weight: 400;
  margin-top: 0.5rem !important;
}

.ant-card {
  box-shadow: var(--bs-box-shadow) !important;
}

.ant-typography {
  margin-top: 0.5rem !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (max-width: 768px) {
  .desktopMenu {
    display: none;
  }
  .mobileMenu {
    display: block;
  }

  .hamburgerIcon {
    color: var(--bs-black-rgb);
    background: none;
    border: none;
    margin-top: 0px;
  }

  .navMenu {
    width: 0;
  }

  .carousel-card .ant-card {
    height: 400px; /* Ensure uniform card height */
  }
  .card-column {
    border-right: none;
  }
  .left-arrow {
    left: -25px;
  }

  .right-arrow {
    right: -25px;
  }
  h1.ant-typography {
    font-size: 40px;
  }
  h2.ant-typography {
    font-size: 30px;
  }

  h3.ant-typography {
    font-size: 20px;
  }
  .ant-layout-header {
    padding: 0;
  }
  .container {
    padding: 0 5%;
  }
}
